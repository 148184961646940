.Wrapper {
  background-color: white;
}

.WrapperScreen {
  width: 100%;
  min-width: 360px;
  max-width: 400px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .Wrapper {
    background-color: #F5F5F5;
  }
  
  
  .WrapperScreen {
    width: 480px;
    max-width: 480px;
    padding: 40px;
    margin-top: 80px;
    margin-bottom: 80px;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
  }
}
